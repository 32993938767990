@use '../../../../app/styles/media-queries.scss' as *;

.accordionItem {
  border-top: 1.5px solid var(--primary-color);
  cursor: pointer;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -moz-tap-highlight-color: rgba(0,0,0,0);
  -ms-tap-highlight-color: rgba(0,0,0,0);
  -o-tap-highlight-color: rgba(0,0,0,0);

  &:focus,
  &:focus-visible,
  &:active,
  .titleContainer:focus,
  .titleContainer:focus-visible,
  .titleContainer:active,
  .titleContainer *:focus,
  .titleContainer *:focus-visible,
  .titleContainer *:active {
    outline: none;
    box-shadow: none;
  }
  .title {
    padding: 30px 0;
  }
  .content {
      margin-bottom: 30px;
      overflow: hidden;
      animation: slideDown 0.3s ease forwards;
  }

  @keyframes slideDown {
    0% {
      max-height: 0;
      opacity: 0;
    }
    25% {
      max-height: 125px;
      opacity: 0.25;
    }
    50% {
      max-height: 250px;
      opacity: 0.5;
    }
    75% {
      max-height: 375px;
      opacity: 0.75;
    }
    100% {
      max-height: 500px;
      opacity: 1;
    }
  }

  @include desktop {
    .content {
      font-size: var(--font-size-md);
    }
  }
}

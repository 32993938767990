@use '../../app/styles/vars.scss' as *;
@use '../../app/styles/media-queries.scss' as *;

.headerNotFound{
    padding: 20px 15px 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mainNotFound{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    padding: 0 15px;
    text-align: center;
}
.number{
    font-size: 150px;
    margin-bottom: 40px;
    color: var(--primary-light-color);
}
.heading{
    font-weight: 500;
}
.subheading{
    margin: 10px 0 50px 0;
}

@include tablet{
    .headerNotFound{
        padding: 20px 80px 0 80px;
    }
}
@include laptop {
    .headerNotFound{
        padding: 40px 120px 0 120px;
    }
    .heading{
        font-size: var(--font-size-lg);
    }
    .subheading{
        font-size: var(--font-size-md);
        margin: 10px 0 70px 0;
    }
    .number{
        font-size: 255px;
        margin-bottom: 70px;
    }
}
@include desktop {
    .headerNotFound{
        padding: 30px 360px 0 360px;
    }
}
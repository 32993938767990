@use '../../app/styles/vars.scss' as * ;
@use '../../app/styles/media-queries.scss' as * ;


.form{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inputField{
    border-radius: var(--button-border-radius);
    border: 1px solid var(--primary-light-color);
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    font-family: var(--font-poppins);

    &:focus {
        border: 1px solid var(--primary-color);
    }
}

.btn{
    margin-top: 30px;
}

.closeBtn {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 2;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.closeBtnSvg {
    width: 15px;
    height: 15px;
}

@include laptop{
    .form{
        margin: auto;
        padding: 30px 0;
    }
    .inputField{
        margin-bottom: 20px;
    }
}
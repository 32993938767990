@use '../../../app/styles/vars.scss' as *;

.button{
    background-color: var(--primary-color);
    font-family: var(--font-poppins);
    font-size: var(--font-size-md);
    padding: 10px 35px;
    border: 1px solid transparent;
    color: white;
    border-radius: var(--button-border-radius);

    svg{
        margin-left: 10px;
        transition: all 0.3s linear;
    }

    &:hover, &:active,  &:focus, &:disabled{
        transition: all 0.3s linear;
    }
    &:hover{
        border: 1px solid var(--primary-color);
        background-color: transparent;
        color: var(--primary-color);

        svg path {
            fill: var(--primary-color);
        }
    }
    &:disabled:hover{
        background-color: var(--gray-color);
        box-shadow: none;
    }

    &:disabled {
        background-color: var(--gray-color);
        cursor: not-allowed; 
        color: white;
        border: 1px solid var(--gray-color); 
        
        &:hover:not(:disabled) {  
            color: white;
            border: none;
        }
    }

    &.navigation{
        background-color: transparent;
        color: var(--black-color);
        border: none;
    }
}
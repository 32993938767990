@use '../../../app/styles/vars.scss' as *;
@import "swiper/css";

.swiper-pagination {
    bottom: 10px !important;
    text-align: center;
}

.swiper-pagination-bullet {
    background-color: var(--light-color);
    opacity: 1;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    transition: background-color 0.3s;
}

.swiper-pagination-bullet-active {
    background-color: var(--primary-color);
}

@use '../../../app/styles/vars.scss' as *;
@use '../../../app/styles/media-queries.scss' as *;

.inputField{
    border-radius: var(--button-border-radius);
    border: 1px solid var(--primary-light-color);
    padding: 10px;
    margin-bottom: 10px;
    font-size: var(--font-size-xs);

    &:focus {
        border: 1px solid var(--primary-color);
    }
}

@include laptop{
    .inputField{
        margin-bottom: 20px;
    }
}
$tablet: 750px;
$laptop: 1440px;
$desktop: 1920px;

@mixin tablet {
    @media (min-width: #{$tablet}) {
        @content;
    }
}

@mixin laptop {
    @media (min-width: #{$laptop}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}
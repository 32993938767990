@use "../../../../app/styles/vars.scss" as *;
@use "../../../../app/styles/media-queries.scss" as *;

.section {
  padding: 0 15px;
  margin: 70px 0;
}

.cards {
  flex-wrap: wrap;
}

.container {
  width: 100%;
  margin: auto;
}

.servicesTitle {
  margin-right: 10px;
}

.servicesSubtitle {
  margin-bottom: 30px;
}

.svgDesktop {
  display: none;
}

@include tablet{
  .section{
      padding: 0 80px;
  }
}

@include laptop {
  .section {
    padding: 0 120px;
    margin: 150px 0;
  }
  .servicesTitle {
    font-size: var(--font-size-xxl);
  }

  .servicesSubtitle {
    font-size: var(--font-size-md);
    margin-bottom: 70px;
  }
  .svgMobile {
    display: none;
  }

  .svgDesktop {
    display: block;
  }
}

@include desktop {
  .section {
    padding: 0 360px;
  }
}

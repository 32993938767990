@font-face {
    font-family: "Poppins";
    src: url('../../shared/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url('../../shared/assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}


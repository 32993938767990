@use '../../app/styles/vars.scss' as *;
@use '../../app/styles/media-queries.scss' as *;

.header{
    padding: 20px 15px 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@include tablet{
    .header{
        padding: 20px 80px 0 80px;
    }
}
@include laptop {
    .header{
        padding: 30px 120px 0 120px;
    }
}
@include desktop {
    .header{
        padding: 30px 360px 0 360px;
    }
}
@use '../../app/styles/vars.scss' as *;
@use '../../app/styles/media-queries.scss' as *;

.sectionContacts{
    padding: 0 15px;
    margin: 70px 0;
}
.text1{
    margin-bottom: -20px;
}
.link{
    color: var(--black-color);
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;

    .svgLink{
        margin-right: 15px;
    }
}
.desktop{
    display: none;
}
.mobile{
    margin-top: 100px;
}
@include tablet{
    .sectionContacts{
        padding: 0 80px;
    }
    .container{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
@include laptop{
    .sectionContacts{
        padding: 0 120px;
        margin: 150px 0;
    }
    .text1{
        font-size: 80px;
        letter-spacing: 3.2px;
        margin-bottom: -50px;
    }
    .text2{
        letter-spacing: 4.2px;
        font-size: 104px;
        font-weight: 500;
    }
    
    .desktop{
        display: flex;
    }
    .mobile{
        display: none;
    }
    .link{
        font-size: var(--font-size-sm);
    }
}

@include desktop{
    .sectionContacts{
        padding: 0 360px;
    }
    .text1{
        font-size: 80px;
        letter-spacing: 3.2px;
        margin-bottom: -50px;
    }
    .text2{
        letter-spacing: 4.2px;
        font-size: 104px;
        font-weight: 500;
    }
    .container{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

@use "../../../../app/styles/vars.scss" as *;
@use "../../../../app/styles/media-queries.scss" as *;

.card {
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  border: var(--primary-color) solid 2px;
  border-radius: var(--button-border-radius);
  padding: 30px;
  position: relative;
}

.icons {
  width: 100px;
}

.title {
  text-transform: uppercase;
  font-weight: bold;
  margin: 20px 0;
}

@include tablet {
  .card {
    flex: 0 0 calc(50% - 40px);
    max-width: 0 0 calc(50% - 40px);
    height: auto;
  }
}

@include laptop {
  .card {
    flex: 0 0 calc(33.33% - 40px);
    max-width: 0 0 calc(33.33% - 40px);
  }
}

@include desktop {
    .title {
        font-size: var(--font-size-lg);
      }
      .content {
        font-size: var(--font-size-md);
      }
}
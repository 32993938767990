@use '../../../../app/styles/vars.scss' as *;
@use '../../../../app/styles/media-queries.scss' as *;

.sectionProjects {
    padding: 0 15px;
    margin: 70px 0;
}
.projectsHeading{
    margin-right: 10px;
}
.svgDesktop{
    display: none;
}
.projectWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 20px;
    margin-top: 70px;
}
.btn{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: var(--font-size-xs);
    padding: 10px 20px;

    &:hover{
        box-shadow: 0px 4px 4px rgba(119, 39, 222, 0.25);
        background-color: var(--light-color) ;
    }
}
.project {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    color: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--button-border-radius);
    border: 1px solid var(--primary-color);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, rgba(217, 217, 217, 0.00) 40%, rgba(15, 15, 15, 0.851) 100%);
        z-index: 1;
        border-radius: var(--button-border-radius);
    }

    .projectContent {
        position: relative;
        z-index: 2;
    }
}
.projectCardTitle{
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.project1, .project2, .project3, .project4{
    min-height: 350px;
    background-position: top;
}
.project1 {
    background-image: url('../../../../shared/assets/images/savannah-world.png');
}

@supports (background-image: url('../../../../shared/assets/images/webp/savannah-world.webp')) {
    .project1 {
        background-image: url('../../../../shared/assets/images/webp/savannah-world.webp');
    }
}

.project2 {
    background-image: url('../../../../shared/assets/images/interior-designer.png');
}

@supports (background-image: url('../../../../shared/assets/images/webp/interior-designer.webp')) {
    .project2 {
        background-image: url('../../../../shared/assets/images/webp/interior-designer.webp');
    }
}

.project3 {
    background-image: url('../../../../shared/assets/images/mortgage-adviser.png');
}

@supports (background-image: url('../../../../shared/assets/images/webp/mortgage-adviser.webp')) {
    .project3 {
        background-image: url('../../../../shared/assets/images/webp/mortgage-adviser.webp');
    }
}

.project4 {
    background-image: url('../../../../shared/assets/images/prototype.png');
}

@supports (background-image: url('../../../../shared/assets/images/webp/prototype.webp')) {
    .project4 {
        background-image: url('../../../../shared/assets/images/webp/prototype.webp');
    }
}


@include tablet{
    .sectionProjects {
        padding: 0 80px;
    }
    .project1, .project2, .project3, .project4{
        min-height: 550px;
    }
}

@include laptop {
    .projectsHeading{
        font-size: var(--font-size-xxl);
    }
    .projectsSubheading{
        font-size: var(--font-size-md);
    }
    .sectionProjects {
        padding: 0 120px;
        margin: 150px 0;
    }
    .project1, .project2, .project3, .project4{
        min-height: 350px;
    }
    .projectWrapper{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows:  repeat(3, 1fr);

        & > *:nth-child(1) {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }
        & > *:nth-child(2) {
            grid-column: 2 / 3;
            grid-row: 1 / 3;
        }
        & > *:nth-child(3) {
            grid-column: 1 / 2;
            grid-row: 2 / 4;
        }
        & > *:nth-child(4) {
            grid-column: 2 / 3;
            grid-row: 3 / 4;
        }
    }
    .project1, .project3  {
        min-height: 400px;
    }
    .projectCardTitle{
        font-size: var(--font-size-xl);
    }
    .btn{
        font-size: var(--font-size-md);
        padding: 10px 35px;
    }
    .svgMobile{
        display: none;
    }
    .svgDesktop{
        display: block;
    }
}
@include desktop {
    .sectionProjects {
        padding: 0 360px;
        margin: 150px 0;
    }
}
@use '../../../../app/styles/vars.scss' as *;
@use '../../../../app/styles/media-queries.scss' as *;

.modalContainer {
    background-color: #FBF9FE;
}
.closeBtn {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 2;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}
.closeBtnSvg {
    width: 15px;
    height: 15px;
}
.mainHeading {
    margin: 30px 0;
}
.heading {
    margin-top: 30px;
}
.projectList {
    list-style-position: inside;
}
.container{
    flex-direction: column-reverse;
}
.imageContainer {
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;

    img {
        height: auto;
        object-fit: cover;
        width: 100%;
    }

    .image1,
    .image2,
    .image3,
    .image4,
    .image5,
    .image6 {
        width: 100%;
        border-radius: var(--button-border-radius);
    }
}
.projectLink{
    margin: 50px auto;
}

@include tablet{
    .imageContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 10px;
        margin-bottom: 10px;

        img {
        width: 100%;
        border: 1px solid var(--black-color);
        border-radius: var(--button-border-radius);
        }

        .image1 {
        grid-column: 1 / 1;
        grid-row: 1 / 3;
        max-width: 130px;
        justify-self: end;
        align-self: center;
        }

        .image2 {
        grid-column: 2 / 4;
        grid-row: 1 / 2;
        max-width: 380px;
        align-self: end;
        }

        .image3 {
            grid-column: 2 / 4;
            grid-row: 2 / 3;
            max-width: 380px;
        }
    }
}

@include laptop {
    .container {
        flex-direction: row;
    }
    .bestPracticeContainer{
        flex: 1;
    }
    .projectLink{
        margin: 50px 0;
    }
}

@include desktop {
    .image1 {
        grid-column: 1 / 1;
        grid-row: 1 / 3;
        max-width: 150px;
        justify-self: end;
        }
        .image2 {
        grid-column: 2 / 4;
        grid-row: 1 / 2;
        max-width: 430px;
        align-self: end;
        }
        .image3 {
            grid-column: 2 / 4;
            grid-row: 2 / 3;
            max-width: 430px;
        }
        .mainHeading{
            font-size: var(--font-size-xxl);
        }
        .text{
            font-size: var(--font-size-md);
        }
}

@use '../../../../app/styles/media-queries.scss' as *;
@use '../../../../app/styles/vars.scss' as *;

.accordion {
    border-bottom: 1.5px solid var(--primary-color);
    margin: 70px 15px;
}
.heading{
    margin-right: 10px;
}
.subheading{
    margin-bottom: 30px;
}
.textStyle{
    margin: 10px 0 30px 0;
}
.svgDesktop {
    display: none;
}
@include tablet {
    .accordion{
        margin: 70px 80px;
    }
}
@include laptop {
    .accordion{
        margin: 150px 120px;
    }
    .svgMobile{
        display: none;
    }
    .svgDesktop{
        display: block;
    }
    .heading{
        font-size: var(--font-size-xxl);
    }
    .subheading{
        margin-bottom: 70px;
    }
    .headingContainer{
        justify-content: flex-end;
    }
}
@include desktop {
    .accordion{
        margin: 150px 360px;
    }
    .textStyle{
        margin: 20px 0 50px 0;
    }
    .title{
        font-size: var(--font-size-xxxl);
    }
}
@use '../../../app/styles/media-queries.scss' as *;
@use '../../../app/styles/vars.scss' as *;

.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2; 
    overflow: hidden; 
}

.overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);  
    width: 100%;
    height: 100%;
    z-index: 1001;
}

.closeBtn {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 10px;
    right: 10px; 
    cursor: pointer; 
    z-index: 1002;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

.closeBtnSvg {
    width: 15px;
    height: 15px;

    @include desktop {
        width: 25px;
        height: 25px;
    }
}

.modalContentWrapper {
    background-color: #FBF9FE;  
    border-radius: var(--button-border-radius);
    max-width: 90%;
    max-height: 90%;  
    overflow-y: auto;  
    padding: 20px; 
    border: 1px solid var(--primary-color);
    border-radius: var(--button-border-radius);
    position: relative;
}

@include desktop {
    .modalContentWrapper {
        max-width: 80%;
        height: 100%;
        overflow-y: auto; 
    }
}
@use 'normalize' as *;
@use 'fonts' as *;
@use 'vars' as *;

body{
    color: var(--black-color);
    font-family: var(--font-poppins);
    background-color: #FBF9FE;
    --swiper-theme-color: var(--primary-color);
}
h1, h2, h3{
    font-weight: 400;
}

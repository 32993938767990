@use '../../../app/styles/vars.scss' as *;
@use '../../../app/styles/media-queries.scss' as *;

.footer{
    padding: 0 15px;
    margin-bottom: 30px;
}
.svg, .svg{
    width: 100%;
}
.navigation{
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 40px 0;
}
.footerContainer{
    padding: 0 0 30px 0;
}
.desktop{
    display: none;
}
@include tablet{
    .footer{
        padding: 0 80px;
    }
}
@include laptop{
    .footer{
        padding: 0 120px;
        margin-bottom: 50px;
    }
    .footerContainer{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 100px 0;
    }
    .navigation{
        flex-direction: row;
        padding: 0;
    }
    .desktop{
        display: block;
    }
}
@include desktop{
    .footer{
        padding: 0 360px;
    }
}
.button {
    border-radius: var(--button-border-radius);
    border: 1px solid var(--primary-light-color);
    padding: 10px;
    margin-bottom: 10px;
    background-color: inherit;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--black-color);
    font-size: var(--font-size-xs);
}

.options {
    z-index: 3;
    background-color: white;
    padding: 10px;
    border: 1px solid var(--primary-light-color);
    border-radius: var(--button-border-radius);
}

.option {
    cursor: pointer;
    margin: 4px;
    padding: 2px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.selected {
    background-color: var(--primary-light-color)
}

.check {
    width: 20px;
    height: 20px;
}
@use '../../../../app/styles/vars.scss' as *;
@use '../../../../app/styles/media-queries.scss' as *;

.navigation{
    display: none;
    list-style: none;
}
.btn {
    position: relative;
    display: inline-block;
    transition: color 0.7s ease;
    padding: 0;
        
        &::after {
            content: '';
            position: absolute;
            bottom: -3px; 
            left: 0;
            width: 0%;
            height: 1px; 
            background-color: var(--primary-color);
            transition: width .7s ease;
        }

        &:hover{
            color: var(--primary-color);
        }

        &:hover::after {
            width: 100%;
        }
    }
@include laptop {
    .navigation{
        display: flex;
        align-items:center;
        gap: 50px;
    }
}
@use '../../../app/styles/vars.scss' as *;
@use '../../../app/styles/media-queries.scss' as *;

.heroSection{
    padding: 0 15px;
    margin: 70px 0;
}

.buttonDesktop{
    display: none;
}
.mainImage{
    width: 100%;
    margin: 25px 0;
}

@include tablet{
    .heroSection{
        padding: 0 80px;
    }
}

@include laptop {
    .heroSection{
        padding: 0 120px;
        margin: 150px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .buttonMobile{
        display: none;
    }
    .buttonDesktop{
        display: block;
        margin-top: 70px;
    }
    .subtitle{
        font-size: var(--font-size-md);
    }
}

@include desktop {
    .heroSection{
        padding: 0 360px;
    }
}
:root{
    --primary-color: #9D3BD0;
    --primary-light-color: #E5B3FF;
    --secondary-color: #937BEC;
    --light-color: #F4F0FF;
    --gray-color: #ABAAB0;
    --light-gray-color: #E8E9E9;
    --black-color: #333333;

    --button-border-radius: 0.938rem;

    --font-size-xxxl: 3.75rem;
    --font-size-xxl: 3rem;
    --font-size-xl: 2.25rem;
    --font-size-lg: 1.375rem;
    --font-size-md: 1.25rem;
    --font-size-sm: 1.125rem;
    --font-size-xs: 1rem;

    --letter-spacing-sm: 0.5px;
    --letter-spacing-md: 1px;

    --font-poppins: 'Poppins', sans-serif;

}

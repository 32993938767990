@use '../../../../app/styles/vars.scss' as *;
@use '../../../../app/styles/media-queries.scss' as *;

.hamburger {
    cursor: pointer;
    width: 24px;
    height: 24px;
    transition: all .25s;
    position: absolute;
    top: 20px;
    right: 0;
    z-index: 3;
    background-color: transparent;
    border: none;
    padding: 0;
}
.hamburger:hover {
    border: none;
}
.lineTop,
.lineMiddle,
.lineBottom {
    position: absolute;
    width: 35px;
    height: 3.5px;
    top: 0;
    right: 15px;
    background-color: var(--primary-color);
    transform: rotate(0);
    transition: all .5s;
}
.lineMiddle {
    transform: translateY(7px);
    margin-top: 3px;
}
.lineBottom {
    transform: translateY(14px);
    margin-top: 6px;
}
.open .hamburger {
    position: absolute;
}
.open .lineTop {
    transform: rotate(45deg) translateY(6px) translateX(6px);
    background-color: var(--light-color);
}
.open .lineMiddle {
    display: none;
    margin-top: 0;
}
.open .lineBottom {
    transform: rotate(-45deg) translateY(6px) translateX(-6px);
    margin-top: 0;
    background-color: var(--light-color);
}
.menu {
    list-style: none;
    background-color: var(--primary-color);
    position: fixed;
    top: -100vh;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    transition: top 0.5s ease-in-out;

    li {
        margin-bottom: 50px;
    }

    &.menuOpen {
        top: 0;
    }
}
.navigationBtn {
    font-size: var(--font-size-lg);
    text-transform: uppercase;
    color: var(--light-color) !important;
    padding: 0;
}
.ctaText {
    margin: 50px 0 30px 0;
    background: linear-gradient(90deg, rgba(229, 179, 255, .8) 0%, rgb(255, 255, 255) 50%, rgba(229, 179, 255, .8) 100%);
    background-size: 200%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: textBlink 8s linear infinite;
    text-align: center;
}
@keyframes textBlink {
    0% {
        background-position: 100%;
    }
    100% {
        background-position: -100%;
    }
}
.ctaBtn {
    background-color: var(--light-color);
    color: var(--primary-color);

    svg path {
        fill: var(--primary-color);
    }

    &:active,
    &:hover {
        border: 1px solid var(--light-color);
        background-color: transparent;
        color: var(--light-color);

        svg path {
            fill: var(--light-color);
        }
    }
}

@include tablet {
    .hamburger {
        margin: 0 80px;
    }
}

@include laptop {
    .hamburger {
        display: none;
    }
}

@use '../../../app/styles/vars.scss' as *;

$font-size-xs: var(--font-size-xs);
$font-size-sm: var(--font-size-sm);
$font-size-md: var(--font-size-md);
$font-size-lg: var(--font-size-lg);
$font-size-xl: var(--font-size-xl);
$font-size-xxl: var(--font-size-xxl);
$font-size-xxxl: var(--font-size-xxxl);


$sizes: (
    xs: $font-size-xs,
    sm: $font-size-sm,
    md: $font-size-md,
    lg: $font-size-lg,
    xl: $font-size-xl,
    xxl: $font-size-xxl,
    xxxl: $font-size-xxxl
);

@each $name, $size in $sizes {
    .#{$name} {
        font-size: $size;
    }
}